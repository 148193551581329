/**
 * Enables a set of allowed HTML entities to be used in text fields and thus
 * getting them rendered by the browser as intended.
 *
 * Currently supported HTML entities: \&shy; and \&nbsp;
 *
 * @param text Text containing allowed HTML entities
 * @returns Text with allowed HTML entities replaced by encoded characters
 */
export const enableHTMLEntities = (text: string) => {
  return text
    .replace(/&nbsp;/gi, String.fromCharCode(160))
    .replace(/&shy;/gi, String.fromCharCode(173))
    .replace(/&ndash;/gi, String.fromCharCode(8211));
};
