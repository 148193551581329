// filename: src/app/utils/debugging.ts

const enum QueryParam {
  Debug = 'd_fa',
  NoTracking = 'notrack_fa',
}

const getParam = (paramName: string): string | null => {
  try {
    if (typeof window !== 'undefined') {
      return new URLSearchParams(window.location.search.substr(1)).get(paramName);
    }
  } catch {
    return null;
  }
  return null;
};

export const isDebugMode = (): boolean => getParam(QueryParam.Debug) === 'true';
export const isTrackingDisabled = (): boolean => getParam(QueryParam.NoTracking) === 'true';

export const debug =
  typeof window !== 'undefined' && isDebugMode()
    ? // eslint-disable-next-line no-console
      console.log.bind(
        window.console,
        `\x1B[47;30m Debug / ${__FEATURE_APP_NAME__} v${__FEATURE_APP_VERSION__} \x1B[m`,
      )
    : // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {};
