import { useContext } from 'react';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { Context } from '../Context';
import { isTrackingDisabled, debug } from './debugging';
import { AppContent, ArticleFormatType } from '../types';

export interface Tracking {
  sendReadyEvent(): void;
  registerImpressionTracking(): void;
}

export function useTracking(
  featureAppProps: AppContent,
  ref: React.MutableRefObject<null>,
  attributes?: {
    version?: string;
    implementer?: number;
    [key: string]: unknown;
  },
): Tracking {
  if (isTrackingDisabled()) {
    debug('TRACKING DISABLED');

    return {
      sendReadyEvent: () => {
        debug('TRACKING not executed: sendReadyEvent');
      },
      registerImpressionTracking: () => {
        debug('TRACKING not executed: registerImpressionTracking');
      },
    };
  }

  // eslint-disable-next-line prefer-rest-params
  debug('Tracking initialized', arguments);

  const { trackingService } = useContext(Context);
  const featureAppName = (typeof __FEATURE_APP_NAME__ === 'string' && __FEATURE_APP_NAME__) || '';
  const componentNameWithDashes = featureAppName.replace('fa-', '');
  const componentNameWithSpaces = componentNameWithDashes.replace(/-/g, ' ');
  const defaultComponentAdditions = {
    componentInfo: {
      // not accepted by tracking lib
      // componentID: 'bla',
    },
    attributes: {
      version: __FEATURE_APP_VERSION__,
      ...attributes,
    },
  };

  return {
    sendReadyEvent: () => {
      if (trackingService) {
        const readyEvent = {
          event: {
            attributes: {
              componentName: componentNameWithDashes,
              elementName: '',
              label: '',
              clickID: '',
              pos: '',
              targetURL: '',
              value: '',
            },
            eventInfo: {
              eventAction: 'feature_app_ready',
              eventName: `${componentNameWithSpaces} - feature app ready`,
            },
          },
          componentUpdate: defaultComponentAdditions,
        };

        debug(
          'dataLayer.page before completing it',
          JSON.stringify(trackingService.getDatalayer().page),
        );

        // update datalayer page object before triggering ready event
        completePageObject(trackingService, featureAppProps);

        debug(
          'dataLayer.page right before feature_app_ready event',
          JSON.stringify(trackingService.getDatalayer().page),
        );

        debug('Tracking: sendReadyEvent', readyEvent);
        trackingService.track(readyEvent);
      }
    },
    registerImpressionTracking: () => {
      if (trackingService && ref.current) {
        debug('Tracking: registerImpressionTracking');
        trackingService.registerImpressionTracking(ref.current, () => {
          return {
            event: {
              attributes: {
                componentName: componentNameWithDashes,
                elementName: '',
                label: '',
                clickID: '',
                pos: '',
                targetURL: '',
                value: '',
              },
              eventInfo: {
                eventAction: 'impression',
                eventName: `${componentNameWithSpaces} - impression`,
              },
            },
            componentUpdate: defaultComponentAdditions,
          };
        });
      }
    },
  };
}

interface TrackingPageAttributes {
  articleFormat?: ArticleFormatType;
  articleID?: string;
  articlePublicationDate?: string;
  articleReadingTime?: string;
}

interface TrackingPageCategory {
  pageType?: string;
}
interface TrackingPage {
  attributes?: TrackingPageAttributes;
  category?: TrackingPageCategory;
}

interface TrackingDatalayer {
  page?: TrackingPage;
}

/**
 * Adds missing attributes/properties to global dataLayer object
 *
 * @param trackingService
 * @param featureAppProps
 */
function completePageObject(trackingService: TrackingServiceV2, featureAppProps: AppContent) {
  const datalayer = trackingService.getDatalayer() as unknown as TrackingDatalayer;

  if (!datalayer.page) {
    datalayer.page = {};
  }

  // complement attributes

  if (!datalayer.page.attributes) {
    datalayer.page.attributes = {};
  }

  if (featureAppProps.articleFormat) {
    datalayer.page.attributes.articleFormat = featureAppProps.articleFormat;
  }

  if (featureAppProps.articleID) {
    datalayer.page.attributes.articleID = featureAppProps.articleID;
  }

  if (featureAppProps.articleCreationDate) {
    datalayer.page.attributes.articlePublicationDate = featureAppProps.articleCreationDate; // attention: different prop names
  }

  if (featureAppProps.articleReadingTime) {
    datalayer.page.attributes.articleReadingTime = featureAppProps.articleReadingTime.toString();
  }
}
