/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import styled from 'styled-components';

import { audiLightTheme, audiDarkTheme, Text, ThemeProvider } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';

import { enableHTMLEntities } from '../../utils/enableHTMLEntities';
import { useTracking } from '../../utils/useTracking';
import { AppContent } from '../../types';
import { LegalInfo } from '../LegalInfo/LegalInfo';
import { InfoRow } from './InfoRow';

const ArticleIntroContainer = styled.div`
  text-align: center;

  ${(props) => {
    return `
      background-color: var(${props.theme.colors.background.level[0]});

      padding: var(${props.theme.responsive.spacing.xxl}) 28px;

      @media (min-width: ${props.theme.breakpoints.m}px) {
        padding: var(${props.theme.responsive.spacing.xxl}) 60px;
      }

      @media (min-width: ${props.theme.breakpoints.l}px) {
        padding: var(${props.theme.responsive.spacing.xxl}) 150px;
      }

      @media (min-width: ${props.theme.breakpoints.xl}px) {
        padding: var(${props.theme.responsive.spacing.xxl}) 150px;
      }

      @media (min-width: ${props.theme.breakpoints.xxl}px) {
        padding: var(${props.theme.responsive.spacing.xxl}) 250px;
      }
    `;
  }}
`;

export const ArticelIntro: React.FC<AppContent> = (props) => {
  const {
    headline,
    copy,
    articleCredits,
    articleReadingTime,
    articleCreationDate,
    consumptionAndEmissions,
    disclaimers,
    theme,
  } = props;

  const ref = React.useRef(null);
  const tracking = useTracking(props, ref, {
    implementer: 37,
  });

  React.useEffect(() => {
    tracking.sendReadyEvent();
    tracking.registerImpressionTracking();
  }, []);

  return (
    <ThemeProvider theme={theme === 'Dark' ? audiDarkTheme : audiLightTheme}>
      <ArticleIntroContainer ref={ref} data-test-id="article-intro">
        <Text variant="order1" as="h1">
          {renderTextWithFootnotesReferencesV2(enableHTMLEntities(headline))}
        </Text>
        <Text variant="order3" as="h2" spaceStackStart="l">
          {renderTextWithFootnotesReferencesV2(enableHTMLEntities(copy))}
        </Text>
        <InfoRow
          articleCredits={articleCredits}
          articleReadingTime={articleReadingTime}
          articleCreationDate={articleCreationDate}
        />
        <LegalInfo consumptionAndEmissions={consumptionAndEmissions} disclaimers={disclaimers} />
      </ArticleIntroContainer>
    </ThemeProvider>
  );
};

ArticelIntro.displayName = 'ArticelIntro';
