/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import styled from 'styled-components';

import { Text } from '@audi/audi-ui-react';
import { useI18n } from '@oneaudi/i18n-context';

import { enableHTMLEntities } from '../../utils/enableHTMLEntities';

const StyledInfoRow = styled.div`
  padding-top: 24px;
`;

interface InfoRowProps {
  articleCredits?: string;
  articleReadingTime?: number;
  articleCreationDate?: string;
}

export const InfoRow: React.FC<InfoRowProps> = (props) => {
  const { articleCredits, articleReadingTime, articleCreationDate } = props;

  if (!articleCredits && !articleReadingTime && !articleCreationDate) {
    return null;
  }

  const textRowChunks: string[] = [];

  if (articleReadingTime) {
    textRowChunks.push(
      useI18n({ id: 'fa.article-intro.reading-time' }, { duration: articleReadingTime }),
    );
  }

  if (articleCredits) {
    textRowChunks.push(enableHTMLEntities(articleCredits));
  }

  if (articleCreationDate) {
    textRowChunks.push(articleCreationDate);
  }

  return (
    <StyledInfoRow>
      <Text variant="copy2" spaceStackStart="xl">
        {textRowChunks.join(enableHTMLEntities(' &ndash; '))}
      </Text>
    </StyledInfoRow>
  );
};

InfoRow.displayName = 'InfoRow';
